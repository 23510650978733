import React, {useState} from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout"
import FaqQuestion from '../components/faq/faq_question'
import SearchIcon from '../components/icons/search_icon'
import BannerBars from '../components/icons/banner_bars'
import "../components/faq/faq.css"

const FaqPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      strapiFaqPage {
        text
        title
        bannerImage{
          localFile{
            publicURL
          }
          
        }
      }
      allStrapiFaq(filter: {language: {eq: "english"}}) {
        group(field: Group) {
          nodes {
            Group
            content
            strapiId
            title
            language
          }
        }
      }
    }
  `)
  const [searchData, setSearchData] = useState(data.allStrapiFaq.group)
  const handleSearch = (e)=>{
    const value = e.target.value
    if(value){
      var groups = data.allStrapiFaq.group
      var newGroups = []
      groups.forEach((g)=>{
        var filteredGroup = {}
        filteredGroup.nodes = g.nodes.filter((f)=>f.title.toLowerCase().includes(value.toLowerCase()))
        if(filteredGroup.nodes.length > 0){
          newGroups.push(filteredGroup)
        }
      })
      if(newGroups.length > 0){
        setSearchData(newGroups)
      } else {
        setSearchData([])
      }
    } else {
      setSearchData(data.allStrapiFaq.group)
    }
    
  }

  return(
    <Layout language="english" path={props.path}>
      <SEO language="english" title="FAQ" />
      <img className="application-mobile-banner" src={data.strapiFaqPage.bannerImage.localFile.publicURL}/>
      <div style={{marginBottom:"40px"}} className="application-top-section">
        <div style={{paddingTop:"20px"}}>
          <h1 className="page-title left">{data.strapiFaqPage.title}</h1>
          <p style={{fontFamily:"Rift"}}>{data.strapiFaqPage.text}</p>
          <div style={{width: "100%"}}>
            <input onChange={handleSearch} type="text" />
            <SearchIcon width={"20px"} height={"20px"} />
          </div>
          
        </div>
        <div style={{position:"relative", backgroundImage:`url("${data.strapiFaqPage.bannerImage.localFile.publicURL}")`}}>
          <BannerBars className="banner-bars" />
          {/* <div/> */}
        </div>
      </div>
      <div className="faq-section">
        {searchData.map((g)=>{
          return(
            <div key={g.nodes[0].Group} className="faq-group">
              <h2 className="section-title">{g.nodes[0].Group.includes("Section_2")?"Coaching":g.nodes[0].Group.includes("Section_3")?"Join us":g.nodes[0].Group}</h2>
              {g.nodes.map((f)=>{
                return <FaqQuestion faq={f} key={f.strapiId}/>
              })}
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default FaqPage