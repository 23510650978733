import React, {useState} from "react"
import DropDownArrow from '../icons/drop_down_arrow'

const FaqQuestion = (props) => {
  const {faq} = props
  const [expanded, setExpanded] = useState(false)
  const faqRef = React.createRef()
  const handleFaqClick = ()=>{
    if(expanded){
      setExpanded(false)
      faqRef.current.style.height = "50px"
    } else {
      setExpanded(true)
      faqRef.current.style.height = `${faqRef.current.scrollHeight}px`
    }
  }
  return(
    <div ref={faqRef} onClick={handleFaqClick} className="faq-card">
      <p style={{color:expanded?"#2fa843":"#1E3B4B"}}>{faq.title}</p>
      <p>{faq.content}</p>
      <DropDownArrow svgStyles={{transform:expanded?"rotate(180deg)":"rotate(0deg)", transition:"transform 0.3s"}}/>
    </div>
  )
}

export default FaqQuestion